import React from "react";
import * as FileSaver from "file-saver";
import * as excel from "exceljs";

export const ExportToExcel = ({ excelData, excelTotalData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (excelData, excelTotalData, fileName) => {
    const workbook = new excel.Workbook();
    const sheet = workbook.addWorksheet("Sheet1");

    sheet.addRow(["", "PCBs Quantity 1", "PCBs Quantity 2", "PCBs Quantity 3"]);
    excelTotalData.forEach((data) =>
      sheet.addRow([
        data.SupplierName,
        data["PCBs Quantity 1"],
        data["PCBs Quantity 2"],
        data["PCBs Quantity 3"],
      ])
    );
    sheet.addRow([]);
    sheet.addRow([]);

    sheet.addRow([
      "Part Name",
      "Footprint",
      "Supplier",
      "Manufacturer Name",
      "MOQ",
      "Availability 1",
      "Total Quantity 1",
      "Unit Price 1",
      "Subtotal Price 1",
      "Availability 2",
      "Total Quantity 2",
      "Unit Price 2",
      "Subtotal Price 2",
      "Availability 3",
      "Total Quantity 3",
      "Unit Price 3",
      "Subtotal Price 3",
    ]);

    excelData.forEach((data) => {
      const row = sheet.addRow([
        data["Part Name"],
        data["Footprint"],
        data["Supplier"],
        data["manufacturerName"],
        data["MOQ"],
        data["AvailabilityFirst"],
        data["Total Quantity 1"],
        data["Unit Price 1"],
        data["Subtotal Price 1"],
        data["AvailabilitySecond"],
        data["Total Quantity 2"],
        data["Unit Price 2"],
        data["Subtotal Price 2"],
        data["AvailabilityThird"],
        data["Total Quantity 3"],
        data["Unit Price 3"],
        data["Subtotal Price 3"],
      ]);
      row.alignment = { horizontal: "left" };
      if (data.firstValid) {
        const cell = sheet.getCell(`I${row._number}`);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "00FF00",
          },
        };
      }
      if (data.secondValid) {
        const cell = sheet.getCell(`M${row._number}`);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "00FF00",
          },
        };
      }
      if (data.thirdValid) {
        const cell = sheet.getCell(`Q${row._number}`);
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "00FF00",
          },
        };
      }
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(blob, fileName + fileExtension);
    });
  };

  return (
    <button
      onClick={(e) => exportToCSV(excelData, excelTotalData, fileName)}
      className="btn btn-primary btn-sm"
    >
      <h6>Export to Excel</h6>
    </button>
  );
};
