import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import GenerateQuote from "../../services/generatequote.service";

function Login(props) {
  let navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      if (data.password !== "") {
        let getResponse = await GenerateQuote.login(data.password);
        if (getResponse.hasOwnProperty("error")) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: getResponse.data,
          });
        } else {
          const accessToken = getResponse.data.accessToken;
          localStorage.setItem(
            "user",
            JSON.stringify({
              accessToken: accessToken,
            })
          );
          navigate("/user");
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: "Login Failed,Please check password.",
          icon: "error",
        });
      }
    },
  });

  return (
    <div className="container">
      <div className="p-3 my-5 d-flex flex-column">
        <div className="row justify-content-md-center">
          <div className="login-form col-sm-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="password"> Password </label>
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <div className="text-danger">
                  {formik.errors.password ? formik.errors.password : null}
                </div>
              </div>

              <div className="form-group mt-4">
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-sm me-1"
                >
                  Login
                </button>

                <button
                  type="button"
                  className="btn btn-outline-warning btn-sm"
                  onClick={formik.handleReset}
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
