import React, { useState, useEffect } from "react";
import GenerateQuote from "../../services/generatequote.service";
import Swal from "sweetalert2";
import { ExportToExcel } from "../../components/ExportToExcel";
import quotoBlueBird from "bluebird";
import { useNavigate } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function UserDashboard() {
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    const decodedJwt = parseJwt(user.accessToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Session Expire,Try Login again.",
      }).then(function () {
        navigate("/login", { replace: true });
      });
    }
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Session Expire,Try Login again.",
    }).then(function () {
      navigate("/login", { replace: true });
    });
  }
  const [pastedData, setPastedData] = useState([]);
  const [pastedMessage, setPastedMessage] = useState(
    "Paste Your Excel Sheet Here."
  );
  const [pastedErrorMessage, setPastedErrorMessage] = useState("");
  const [generateQuotoData, setGenerateQuotoData] = useState([]);
  const [arrow, setArrow] = useState(false);
  const [tme, setTme] = useState(false);
  const [farnell, setFarnell] = useState(false);
  const [digikey, setDigikey] = useState(false);
  const [mouser, setMouser] = useState(false);
  const [arrowTotalFirst, setArrowTotalFirst] = useState(0.0);
  const [arrowTotalSecond, setArrowTotalSecond] = useState(0.0);
  const [arrowTotalThird, setArrowTotalThird] = useState(0.0);
  const [tmeTotalFirst, setTmeTotalFirst] = useState(0.0);
  const [tmeTotalSecond, setTmeTotalSecond] = useState(0.0);
  const [tmeTotalThird, setTmeTotalThird] = useState(0.0);
  const [farnellTotalFirst, setFarnellTotalFirst] = useState(0.0);
  const [farnellTotalSecond, setFarnellTotalSecond] = useState(0.0);
  const [farnellTotalThird, setFarnellTotalThird] = useState(0.0);
  const [mouserTotalFirst, setMouserTotalFirst] = useState(0.0);
  const [mouserTotalSecond, setMouserTotalSecond] = useState(0.0);
  const [mouserTotalThird, setMouserTotalThird] = useState(0.0);
  const [digikeyTotalFirst, setDigikeyTotalFirst] = useState(0.0);
  const [digikeyTotalSecond, setDigikeyTotalSecond] = useState(0.0);
  const [digikeyTotalThird, setDigikeyTotalThird] = useState(0.0);
  const [arrowData, setArrowData] = useState([]);
  const [tmeData, setTmeData] = useState([]);
  const [farnellData, setFarnellData] = useState([]);
  const [digikeyData, setDigikeyData] = useState([]);
  const [mouserData, setMouserData] = useState([]);
  const [pcbquantityFirst, setPcbquantityFirst] = useState(1);
  const [pcbquantitySecond, setPcbquantitySecond] = useState(1);
  const [pcbquantityThird, setPcbquantityThird] = useState(1);
  const [generateExcelData, setGenerateExcelData] = useState([]);
  const [generateExcelTotalData, setGenerateExcelTotalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("false");
  let fileName = "";
  const today = new Date(),
    date =
      today.getHours() +
      "-" +
      today.getMinutes() +
      "-" +
      today.getSeconds() +
      "-" +
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
  fileName = "GenerateQuoteData-" + date;
  const [style, setStyle] = useState("");

  const changeStyle = () => {
    setStyle("border-width");
  };

  useEffect(() => {
    let newExcelTotalData = [];
    if (arrowData.length > 0) {
      newExcelTotalData.push({
        SupplierName: "Arrow Total",
        "PCBs Quantity 1": "€" + arrowTotalFirst,
        "PCBs Quantity 2": "€" + arrowTotalSecond,
        "PCBs Quantity 3": "€" + arrowTotalThird,
      });
    }
    if (tmeData.length > 0) {
      newExcelTotalData.push({
        SupplierName: "TME Total",
        "PCBs Quantity 1": "€" + tmeTotalFirst,
        "PCBs Quantity 2": "€" + tmeTotalSecond,
        "PCBs Quantity 3": "€" + tmeTotalThird,
      });
    }
    if (farnellData.length > 0) {
      newExcelTotalData.push({
        SupplierName: "Farnell Total",
        "PCBs Quantity 1": "€" + farnellTotalFirst,
        "PCBs Quantity 2": "€" + farnellTotalSecond,
        "PCBs Quantity 3": "€" + farnellTotalThird,
      });
    }
    if (mouserData.length > 0) {
      newExcelTotalData.push({
        SupplierName: "Mouser Total",
        "PCBs Quantity 1": "€" + mouserTotalFirst,
        "PCBs Quantity 2": "€" + mouserTotalSecond,
        "PCBs Quantity 3": "€" + mouserTotalThird,
      });
    }
    if (digikeyData.length > 0) {
      newExcelTotalData.push({
        SupplierName: "DigiKey Total",
        "PCBs Quantity 1": "€" + digikeyTotalFirst,
        "PCBs Quantity 2": "€" + digikeyTotalSecond,
        "PCBs Quantity 3": "€" + digikeyTotalThird,
      });
    }
    setGenerateExcelTotalData(newExcelTotalData);
  }, [
    farnellData,
    arrowData,
    digikeyData,
    mouserData,
    tmeData,
    arrowTotalFirst,
    arrowTotalSecond,
    arrowTotalThird,
    digikeyTotalFirst,
    digikeyTotalSecond,
    digikeyTotalThird,
    farnellTotalFirst,
    farnellTotalSecond,
    farnellTotalThird,
    mouserTotalFirst,
    mouserTotalSecond,
    mouserTotalThird,
    tmeTotalFirst,
    tmeTotalSecond,
    tmeTotalThird,
  ]);
  useEffect(() => {
    const handlePaste = (event) => {
      if (
        event.target.tagName &&
        event.target.tagName.match(/(input|textarea)/i)
      ) {
        return;
      }
      setPastedErrorMessage("");
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData("text");
      const rows = pastedText.split("\n");
      let result = rows.map((row) => {
        const cells = row.split("\t");
        if (cells.length > 0) {
          if (cells[0] !== "") {
            if (cells.hasOwnProperty("1") && cells[1].trim() !== "") {
              if (isNaN(cells[1])) {
                setPastedErrorMessage("unitsPerPCB allow only number.");
              }
              return {
                partName: cells[0].trim(),
                unitsPerPCB: cells[1].trim(),
                color: cells[1] === undefined ? "errorMessage" : "",
              };
            } else {
              setPastedErrorMessage("unitsPerPCB Value require.");
              return {
                partName: cells[0].trim(),
                unitsPerPCB: "",
                color: "errorMessage",
              };
            }
          }
        }
        return null;
      });
      result = result.filter(function (el) {
        return el != null;
      });
      if (result.length > 0) {
        setPastedMessage("");
      } else {
        setPastedMessage("Paste Your Excel Sheet Here.");
      }
      setPastedData(result);
    };
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  const handleReset = (e) => {
    setGenerateQuotoData([]);
    setPastedMessage("Paste Your Excel Sheet Here.");
    setPastedData([]);
    setArrow(false);
    setTme(false);
    setFarnell(false);
    setDigikey(false);
    setMouser(false);
    setArrowTotalFirst(0.0);
    setArrowTotalSecond(0.0);
    setArrowTotalThird(0.0);
    setTmeTotalFirst(0.0);
    setTmeTotalSecond(0.0);
    setTmeTotalThird(0.0);
    setFarnellTotalFirst(0.0);
    setFarnellTotalSecond(0.0);
    setFarnellTotalThird(0.0);
    setMouserTotalFirst(0.0);
    setMouserTotalSecond(0.0);
    setMouserTotalThird(0.0);
    setDigikeyTotalFirst(0.0);
    setDigikeyTotalSecond(0.0);
    setDigikeyTotalThird(0.0);
    setPcbquantityFirst(1);
    setPcbquantitySecond(1);
    setPcbquantityThird(1);
    setGenerateExcelData([]);
    setGenerateExcelTotalData([]);
    setArrowData([]);
    setTmeData([]);
    setFarnellData([]);
    setMouserData([]);
    setDigikeyData([]);
    setStyle([]);
    setPastedErrorMessage("");
  };
  const handleGenerateQuote = (e) => {
    if (pastedData.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please paste proper data.",
        icon: "error",
      });
      return null;
    }
    if (pastedErrorMessage !== "") {
      Swal.fire({
        title: "Error!",
        text: pastedErrorMessage,
        icon: "error",
      });
      return null;
    }
    if (!mouser && !arrow && !tme && !farnell && !digikey) {
      Swal.fire({
        title: "Error!",
        text: "Please Select one of the supplier.",
        icon: "error",
      });
      return null;
    }
    if (pcbquantityFirst < 1 || pcbquantitySecond < 1 || pcbquantityThird < 1) {
      Swal.fire({
        title: "Error!",
        text: "Please Enter Proper PCBs Quantity.",
        icon: "error",
      });
      return null;
    }
    try {
      setGenerateQuotoData([]);
      setArrowData([]);
      setTmeData([]);
      setFarnellData([]);
      setMouserData([]);
      setDigikeyData([]);
      setArrowTotalFirst(0.0);
      setArrowTotalSecond(0.0);
      setArrowTotalThird(0.0);
      setTmeTotalFirst(0.0);
      setTmeTotalSecond(0.0);
      setTmeTotalThird(0.0);
      setFarnellTotalFirst(0.0);
      setFarnellTotalSecond(0.0);
      setFarnellTotalThird(0.0);
      setMouserTotalFirst(0.0);
      setMouserTotalSecond(0.0);
      setMouserTotalThird(0.0);
      setDigikeyTotalFirst(0.0);
      setDigikeyTotalSecond(0.0);
      setDigikeyTotalThird(0.0);
      setGenerateExcelData([]);
      setGenerateExcelTotalData([]);
      setLoading(true);
      setLoadingMessage("generating Quote.");
      quotoBlueBird
        .map(
          pastedData,
          async (items, i) => {
            await quotoBlueBird.delay(2000);
            let counterId = 0;
            let gqData = {
              [items.partName]: [],
            };
            let totalQtyFirst = Math.round(
              items.unitsPerPCB * pcbquantityFirst
            );
            let totalQtySecond = Math.round(
              items.unitsPerPCB * pcbquantitySecond
            );
            let totalQtyThird = Math.round(
              items.unitsPerPCB * pcbquantityThird
            );
            if (arrow) {
              let priceFirst = 0;
              let priceSecond = 0;
              let priceThird = 0;
              let footPrint = "";
              let manufacturerName = "";
              let availabilityFirst = "out of Stock";
              let availabilitySecond = "out of Stock";
              let availabilityThird = "out of Stock";
              let moq = 0;
              let subTotalPriceFirst = 0;
              let subTotalPriceSecond = 0;
              let subTotalPriceThird = 0;
              let getArrowResponse = await GenerateQuote.getArrow(
                items.partName
              );
              if (
                getArrowResponse.hasOwnProperty("itemserviceresult") &&
                getArrowResponse.itemserviceresult.data.length > 0 &&
                getArrowResponse.itemserviceresult.transactionArea[0].response
                  .success === true
              ) {
                getArrowResponse.itemserviceresult.data[0].PartList.map(
                  (arrowItems) => {
                    if (
                      items.partName === arrowItems.partNum &&
                      arrowItems.hasOwnProperty("InvOrg")
                    ) {
                      arrowItems.InvOrg.webSites.map((webItems) => {
                        webItems.sources.map((sourceItems) => {
                          if (sourceItems.currency === "EUR") {
                            sourceItems.sourceParts.map((sourcePartsItems) => {
                              priceFirst = 0;
                              priceSecond = 0;
                              priceThird = 0;
                              footPrint = "";
                              manufacturerName = "";
                              availabilityFirst = "out of Stock";
                              availabilitySecond = "out of Stock";
                              availabilityThird = "out of Stock";
                              moq = 0;
                              subTotalPriceFirst = 0;
                              subTotalPriceSecond = 0;
                              subTotalPriceThird = 0;
                              if (
                                sourcePartsItems.productCode === items.partName
                              ) {
                                manufacturerName =
                                  arrowItems.manufacturer.mfrName;
                                moq = sourcePartsItems.minimumOrderQuantity;
                                if (
                                  sourcePartsItems.Availability[0].fohQty >=
                                  totalQtyFirst
                                ) {
                                  availabilityFirst =
                                    sourcePartsItems.Availability[0].fohQty;
                                }
                                if (
                                  sourcePartsItems.Availability[0].fohQty >=
                                  totalQtySecond
                                ) {
                                  availabilitySecond =
                                    sourcePartsItems.Availability[0].fohQty;
                                }
                                if (
                                  sourcePartsItems.Availability[0].fohQty >=
                                  totalQtyThird
                                ) {
                                  availabilityThird =
                                    sourcePartsItems.Availability[0].fohQty;
                                }
                                if (
                                  sourcePartsItems.hasOwnProperty("Prices") &&
                                  sourcePartsItems.Prices.hasOwnProperty(
                                    "resaleList"
                                  )
                                ) {
                                  if (moq <= totalQtyFirst) {
                                    sourcePartsItems.Prices.resaleList.map(
                                      (priceItems) => {
                                        if (
                                          totalQtyFirst >= priceItems.minQty &&
                                          totalQtyFirst <= priceItems.maxQty
                                        ) {
                                          priceFirst = priceItems.price;
                                          subTotalPriceFirst =
                                            totalQtyFirst * priceItems.price;
                                        }
                                      }
                                    );
                                  }
                                  if (moq <= totalQtySecond) {
                                    sourcePartsItems.Prices.resaleList.map(
                                      (priceItems) => {
                                        if (
                                          totalQtySecond >= priceItems.minQty &&
                                          totalQtySecond <= priceItems.maxQty
                                        ) {
                                          priceSecond = priceItems.price;
                                          subTotalPriceSecond =
                                            totalQtySecond * priceItems.price;
                                        }
                                      }
                                    );
                                  }
                                  if (moq <= totalQtyThird) {
                                    sourcePartsItems.Prices.resaleList.map(
                                      (priceItems) => {
                                        if (
                                          totalQtyThird >= priceItems.minQty &&
                                          totalQtyThird <= priceItems.maxQty
                                        ) {
                                          priceThird = priceItems.price;
                                          subTotalPriceThird =
                                            totalQtyThird * priceItems.price;
                                        }
                                      }
                                    );
                                  }
                                }
                                gqData[items.partName].push({
                                  counterId: (counterId = counterId + 1),
                                  "Part Name": items.partName,
                                  "Total Quantity 1": totalQtyFirst,
                                  "Total Quantity 2": totalQtySecond,
                                  "Total Quantity 3": totalQtyThird,
                                  manufacturerName: manufacturerName,
                                  Footprint: footPrint,
                                  Supplier: "Arrow",
                                  AvailabilityFirst: availabilityFirst,
                                  AvailabilitySecond: availabilitySecond,
                                  AvailabilityThird: availabilityThird,
                                  "Unit Price 1": "€" + priceFirst,
                                  "Unit Price 2": "€" + priceSecond,
                                  "Unit Price 3": "€" + priceThird,
                                  MOQ: moq,
                                  "Subtotal Price 1":
                                    "€" +
                                    Math.round(subTotalPriceFirst * 100) / 100,
                                  "Subtotal Price 2":
                                    "€" +
                                    Math.round(subTotalPriceSecond * 100) / 100,
                                  "Subtotal Price 3":
                                    "€" +
                                    Math.round(subTotalPriceThird * 100) / 100,
                                });
                              }
                            });
                          }
                        });
                      });
                    }
                  }
                );
              }
              if (
                !gqData[items.partName].some(
                  (el) =>
                    el["Part Name"] === items.partName &&
                    el["Supplier"] === "Arrow"
                )
              ) {
                gqData[items.partName].push({
                  counterId: (counterId = counterId + 1),
                  "Part Name": items.partName,
                  "Total Quantity 1": totalQtyFirst,
                  "Total Quantity 2": totalQtySecond,
                  "Total Quantity 3": totalQtyThird,
                  Footprint: "",
                  manufacturerName: "",
                  Supplier: "Arrow",
                  AvailabilityFirst: 0,
                  AvailabilitySecond: 0,
                  availabilityThird: 0,
                  "Unit Price 1": 0,
                  "Unit Price 2": 0,
                  "Unit Price 3": 0,
                  MOQ: 0,
                  "Subtotal Price 1": 0,
                  "Subtotal Price 2": 0,
                  "Subtotal Price 3": 0,
                });
              }
            }

            if (mouser) {
              let priceFirst = 0;
              let priceSecond = 0;
              let priceThird = 0;
              let footPrint = "";
              let manufacturerName = "";
              let availabilityFirst = "out of Stock";
              let availabilitySecond = "out of Stock";
              let availabilityThird = "out of Stock";
              let moq = 0;
              let subTotalPriceFirst = 0;
              let subTotalPriceSecond = 0;
              let subTotalPriceThird = 0;
              let getMouserResponse = await GenerateQuote.getMouser(
                items.partName
              );
              if (
                getMouserResponse.hasOwnProperty("SearchResults") &&
                getMouserResponse.SearchResults !== null
              ) {
                // eslint-disable-next-line
                getMouserResponse.SearchResults.Parts.map((item) => {
                  if (items.partName === item.ManufacturerPartNumber) {
                    if (item.hasOwnProperty("Manufacturer")) {
                      manufacturerName = item.Manufacturer;
                    }
                    if (totalQtyFirst < item.AvailabilityInStock) {
                      availabilityFirst = item.AvailabilityInStock;
                    }
                    if (totalQtySecond < item.AvailabilityInStock) {
                      availabilitySecond = item.AvailabilityInStock;
                    }
                    if (totalQtyThird < item.AvailabilityInStock) {
                      availabilityThird = item.AvailabilityInStock;
                    }
                    if (item.hasOwnProperty("Min")) {
                      moq = item.Min;
                    }
                    let moqFirst = moq;
                    let moqSecond = moq;
                    let moqThird = moq;
                    if (item.hasOwnProperty("Mult") && item.Mult > 0) {
                      if (totalQtyFirst > moq) {
                        if (totalQtyFirst % item.Mult) {
                          let newValue = parseInt(totalQtyFirst / item.Mult);
                          newValue = newValue + 1;
                          moqFirst = item.Mult * newValue;
                        }
                      }
                      if (totalQtySecond > moq) {
                        if (totalQtySecond % item.Mult) {
                          let newValue = parseInt(totalQtySecond / item.Mult);
                          newValue = newValue + 1;
                          moqSecond = item.Mult * newValue;
                        }
                      }
                      if (totalQtyThird > moq) {
                        if (totalQtyThird % item.Mult) {
                          let newValue = parseInt(totalQtyThird / item.Mult);
                          newValue = newValue + 1;
                          moqThird = item.Mult * newValue;
                        }
                      }
                    }
                    let newTQFirst = 0;
                    let newTQSecond = 0;
                    let newTQThird = 0;
                    if (moqFirst > totalQtyFirst) {
                      newTQFirst = moqFirst;
                    } else {
                      newTQFirst = totalQtyFirst;
                    }
                    if (moqSecond > totalQtySecond) {
                      newTQSecond = moqSecond;
                    } else {
                      newTQSecond = totalQtySecond;
                    }
                    if (moqThird > totalQtyThird) {
                      newTQThird = moqThird;
                    } else {
                      newTQThird = totalQtyThird;
                    }
                    if (item.hasOwnProperty("PriceBreaks")) {
                      // eslint-disable-next-line
                      item.PriceBreaks.map((i) => {
                        if (i.Quantity > newTQFirst) {
                          // eslint-disable-next-line
                          return;
                        } else {
                          priceFirst = i.Price.replace(/[₹€]/g, "").replace(
                            /[,]/g,
                            "."
                          );
                          subTotalPriceFirst =
                            i.Price.replace(/[₹€]/g, "").replace(/[,]/g, ".") *
                            newTQFirst;
                        }
                      });
                      // eslint-disable-next-line
                      item.PriceBreaks.map((i) => {
                        if (i.Quantity > newTQSecond) {
                          // eslint-disable-next-line
                          return;
                        } else {
                          priceSecond = i.Price.replace(/[₹€]/g, "").replace(
                            /[,]/g,
                            "."
                          );
                          subTotalPriceSecond =
                            i.Price.replace(/[₹€]/g, "").replace(/[,]/g, ".") *
                            newTQSecond;
                        }
                      });
                      // eslint-disable-next-line
                      item.PriceBreaks.map((i) => {
                        if (i.Quantity > newTQThird) {
                          // eslint-disable-next-line
                          return;
                        } else {
                          priceThird = i.Price.replace(/[₹€]/g, "").replace(
                            /[,]/g,
                            "."
                          );
                          subTotalPriceThird =
                            i.Price.replace(/[₹€]/g, "").replace(/[,]/g, ".") *
                            newTQThird;
                        }
                      });
                    }
                    gqData[items.partName].push({
                      counterId: (counterId = counterId + 1),
                      "Part Name": items.partName,
                      "Total Quantity 1": totalQtyFirst,
                      "Total Quantity 2": totalQtySecond,
                      "Total Quantity 3": totalQtyThird,
                      manufacturerName: manufacturerName,
                      Footprint: footPrint,
                      Supplier: "Mouser",
                      AvailabilityFirst: availabilityFirst,
                      AvailabilitySecond: availabilitySecond,
                      AvailabilityThird: availabilityThird,
                      "Unit Price 1": "€" + priceFirst,
                      "Unit Price 2": "€" + priceSecond,
                      "Unit Price 3": "€" + priceThird,
                      MOQ: moq,
                      "Subtotal Price 1":
                        "€" + Math.round(subTotalPriceFirst * 100) / 100,
                      "Subtotal Price 2":
                        "€" + Math.round(subTotalPriceSecond * 100) / 100,
                      "Subtotal Price 3":
                        "€" + Math.round(subTotalPriceThird * 100) / 100,
                    });
                  }
                });
              }
              if (
                !gqData[items.partName].some(
                  (el) =>
                    el["Part Name"] === items.partName &&
                    el["Supplier"] === "Mouser"
                )
              ) {
                gqData[items.partName].push({
                  counterId: (counterId = counterId + 1),
                  "Part Name": items.partName,
                  "Total Quantity 1": totalQtyFirst,
                  "Total Quantity 2": totalQtySecond,
                  "Total Quantity 3": totalQtyThird,
                  Footprint: "",
                  manufacturerName: "",
                  Supplier: "Mouser",
                  AvailabilityFirst: 0,
                  AvailabilitySecond: 0,
                  availabilityThird: 0,
                  "Unit Price 1": 0,
                  "Unit Price 2": 0,
                  "Unit Price 3": 0,
                  MOQ: 0,
                  "Subtotal Price 1": 0,
                  "Subtotal Price 2": 0,
                  "Subtotal Price 3": 0,
                });
              }
            }
            if (farnell) {
              let priceFirst = 0;
              let priceSecond = 0;
              let priceThird = 0;
              let footPrint = "";
              let manufacturerName = "";
              let availabilityFirst = "out of Stock";
              let availabilitySecond = "out of Stock";
              let availabilityThird = "out of Stock";
              let moq = 0;
              let subTotalPriceFirst = 0;
              let subTotalPriceSecond = 0;
              let subTotalPriceThird = 0;
              let getFarnellResponse = await GenerateQuote.getFarnell(
                items.partName
              );
              if (
                getFarnellResponse.hasOwnProperty(
                  "manufacturerPartNumberSearchReturn"
                ) &&
                getFarnellResponse.manufacturerPartNumberSearchReturn !==
                  null &&
                getFarnellResponse.manufacturerPartNumberSearchReturn
                  .numberOfResults > 0
              ) {
                // eslint-disable-next-line
                getFarnellResponse.manufacturerPartNumberSearchReturn.products.map(
                  // eslint-disable-next-line
                  (item) => {
                    if (
                      items.partName === item.translatedManufacturerPartNumber
                    ) {
                      if (item.hasOwnProperty("vendorName")) {
                        manufacturerName = item.vendorName;
                      }
                      let fp = "";
                      let pine = "";
                      if (item.hasOwnProperty("attributes")) {
                        // eslint-disable-next-line
                        item.attributes.map((attb) => {
                          if (
                            attb.attributeLabel.includes("Encapsulado") &&
                            fp === ""
                          ) {
                            fp = attb.attributeValue;
                            return null;
                          }
                        });
                        // eslint-disable-next-line
                        item.attributes.map((attb) => {
                          if (
                            attb.hasOwnProperty("attributeUnit") &&
                            attb.attributeUnit === "Pines"
                          ) {
                            pine = attb.attributeValue;
                            return null;
                          }
                        });
                      }
                      footPrint = pine === "" ? fp : fp + " - " + pine;
                      if (totalQtyFirst < item.stock.level) {
                        availabilityFirst = item.stock.level;
                      }
                      if (totalQtySecond < item.stock.level) {
                        availabilitySecond = item.stock.level;
                      }
                      if (totalQtyThird < item.stock.level) {
                        availabilityThird = item.stock.level;
                      }

                      if (
                        item.hasOwnProperty("translatedMinimumOrderQuality")
                      ) {
                        moq = item.translatedMinimumOrderQuality;
                      }
                      let moqFirst = moq;
                      let moqSecond = moq;
                      let moqThird = moq;
                      if (
                        item.hasOwnProperty("orderMultiples") &&
                        item.orderMultiples > 0
                      ) {
                        if (
                          totalQtyFirst > item.translatedMinimumOrderQuality
                        ) {
                          if (totalQtyFirst % item.orderMultiples) {
                            let newValue = parseInt(
                              totalQtyFirst / item.orderMultiples
                            );
                            newValue = newValue + 1;
                            moqFirst = item.orderMultiples * newValue;
                          }
                        }
                        if (
                          totalQtySecond > item.translatedMinimumOrderQuality
                        ) {
                          if (totalQtySecond % item.orderMultiples) {
                            let newValue = parseInt(
                              totalQtySecond / item.orderMultiples
                            );
                            newValue = newValue + 1;
                            moqSecond = item.orderMultiples * newValue;
                          }
                        }
                        if (
                          totalQtyThird > item.translatedMinimumOrderQuality
                        ) {
                          if (totalQtyThird % item.orderMultiples) {
                            let newValue = parseInt(
                              totalQtyThird / item.orderMultiples
                            );
                            newValue = newValue + 1;
                            moqThird = item.orderMultiples * newValue;
                          }
                        }
                      }
                      let newTQFirst = 0;
                      let newTQSecond = 0;
                      let newTQThird = 0;
                      if (moqFirst > totalQtyFirst) {
                        newTQFirst = moqFirst;
                      } else {
                        newTQFirst = totalQtyFirst;
                      }
                      if (moqSecond > totalQtySecond) {
                        newTQSecond = moqSecond;
                      } else {
                        newTQSecond = totalQtySecond;
                      }
                      if (moqThird > totalQtyThird) {
                        newTQThird = moqThird;
                      } else {
                        newTQThird = totalQtyThird;
                      }

                      if (item.hasOwnProperty("prices")) {
                        let ifarnelFirst = 0;
                        // eslint-disable-next-line
                        item.prices.map((i) => {
                          if (i.from <= newTQFirst && i.to >= newTQFirst) {
                            priceFirst = i.cost;
                            subTotalPriceFirst = i.cost * newTQFirst;
                            ifarnelFirst = ifarnelFirst + 1;
                          }
                        });
                        if (ifarnelFirst === 0) {
                          priceFirst = item.prices[0].cost;
                          subTotalPriceFirst = item.prices[0].cost * newTQFirst;
                        }
                        let ifarnelSecond = 0;
                        // eslint-disable-next-line
                        item.prices.map((i) => {
                          if (i.from <= newTQSecond && i.to >= newTQSecond) {
                            priceSecond = i.cost;
                            subTotalPriceSecond = i.cost * newTQSecond;
                            ifarnelSecond = ifarnelSecond + 1;
                          }
                        });
                        if (ifarnelSecond === 0) {
                          priceSecond = item.prices[0].cost;
                          subTotalPriceSecond =
                            item.prices[0].cost * newTQSecond;
                        }
                        let ifarnelThird = 0;
                        // eslint-disable-next-line
                        item.prices.map((i) => {
                          if (i.from <= newTQThird && i.to >= newTQThird) {
                            priceThird = i.cost;
                            subTotalPriceThird = i.cost * newTQThird;
                            ifarnelThird = ifarnelThird + 1;
                          }
                        });
                        if (ifarnelThird === 0) {
                          priceThird = item.prices[0].cost;
                          subTotalPriceThird = item.prices[0].cost * newTQThird;
                        }
                      }
                      gqData[items.partName].push({
                        counterId: (counterId = counterId + 1),
                        "Part Name": items.partName,
                        "Total Quantity 1": totalQtyFirst,
                        "Total Quantity 2": totalQtySecond,
                        "Total Quantity 3": totalQtyThird,
                        Footprint: footPrint,
                        manufacturerName: manufacturerName,
                        Supplier: "Farnell",
                        AvailabilityFirst: availabilityFirst,
                        AvailabilitySecond: availabilitySecond,
                        AvailabilityThird: availabilityThird,
                        "Unit Price 1": "€" + priceFirst,
                        "Unit Price 2": "€" + priceSecond,
                        "Unit Price 3": "€" + priceThird,
                        MOQ: moq,
                        "Subtotal Price 1":
                          "€" + Math.round(subTotalPriceFirst * 100) / 100,
                        "Subtotal Price 2":
                          "€" + Math.round(subTotalPriceSecond * 100) / 100,
                        "Subtotal Price 3":
                          "€" + Math.round(subTotalPriceThird * 100) / 100,
                      });
                    }
                  }
                );
              }
              if (
                !gqData[items.partName].some(
                  (el) =>
                    el["Part Name"] === items.partName &&
                    el["Supplier"] === "Farnell"
                )
              ) {
                gqData[items.partName].push({
                  counterId: (counterId = counterId + 1),
                  "Part Name": items.partName,
                  "Total Quantity 1": totalQtyFirst,
                  "Total Quantity 2": totalQtySecond,
                  "Total Quantity 3": totalQtyThird,
                  Footprint: "",
                  manufacturerName: "",
                  Supplier: "Farnell",
                  AvailabilityFirst: 0,
                  AvailabilitySecond: 0,
                  AvailabilityThird: 0,
                  "Unit Price 1": 0,
                  "Unit Price 2": 0,
                  "Unit Price 3": 0,
                  MOQ: 0,
                  "Subtotal Price 1": 0,
                  "Subtotal Price 2": 0,
                  "Subtotal Price 3": 0,
                });
              }
            }
            if (tme) {
              let priceFirst = 0;
              let priceSecond = 0;
              let priceThird = 0;
              let footPrint = "";
              let manufacturerName = "";
              let availabilityFirst = "out of Stock";
              let availabilitySecond = "out of Stock";
              let availabilityThird = "out of Stock";
              let moq = 0;
              let subTotalPriceFirst = 0;
              let subTotalPriceSecond = 0;
              let subTotalPriceThird = 0;
              let getTmeResponse = await GenerateQuote.getTme(items.partName);

              if (!getTmeResponse.hasOwnProperty("Error")) {
                if (getTmeResponse.Symbol === items.partName) {
                  if (getTmeResponse.hasOwnProperty("Producer")) {
                    manufacturerName = getTmeResponse.Producer;
                  }
                  if (totalQtyFirst < getTmeResponse.Amount) {
                    availabilityFirst = getTmeResponse.Amount;
                  }
                  if (totalQtySecond < getTmeResponse.Amount) {
                    availabilitySecond = getTmeResponse.Amount;
                  }
                  if (totalQtyThird < getTmeResponse.Amount) {
                    availabilityThird = getTmeResponse.Amount;
                  }
                  moq = getTmeResponse.MinAmount;
                  let moqFirst = moq;
                  let moqSecond = moq;
                  let moqThird = moq;
                  if (
                    getTmeResponse.hasOwnProperty("Multiples") &&
                    getTmeResponse.Multiples > 0
                  ) {
                    if (totalQtyFirst > getTmeResponse.MinAmount) {
                      if (totalQtyFirst % getTmeResponse.Multiples) {
                        let newValue = parseInt(
                          totalQtyFirst / getTmeResponse.Multiples
                        );
                        newValue = newValue + 1;
                        moqFirst = getTmeResponse.Multiples * newValue;
                      }
                    }
                    if (totalQtySecond > getTmeResponse.MinAmount) {
                      if (totalQtySecond % getTmeResponse.Multiples) {
                        let newValue = parseInt(
                          totalQtySecond / getTmeResponse.Multiples
                        );
                        newValue = newValue + 1;
                        moqSecond = getTmeResponse.Multiples * newValue;
                      }
                    }
                    if (totalQtyThird > getTmeResponse.MinAmount) {
                      if (totalQtyThird % getTmeResponse.Multiples) {
                        let newValue = parseInt(
                          totalQtyThird / getTmeResponse.Multiples
                        );
                        newValue = newValue + 1;
                        moqThird = getTmeResponse.Multiples * newValue;
                      }
                    }
                  }
                  let newTQFirst = 0;
                  let newTQSecond = 0;
                  let newTQThird = 0;
                  if (moqFirst > totalQtyFirst) {
                    newTQFirst = moqFirst;
                  } else {
                    newTQFirst = totalQtyFirst;
                  }
                  if (moqSecond > totalQtySecond) {
                    newTQSecond = moqSecond;
                  } else {
                    newTQSecond = totalQtySecond;
                  }
                  if (moqThird > totalQtyThird) {
                    newTQThird = moqThird;
                  } else {
                    newTQThird = totalQtyThird;
                  }
                  let itmeFirst = 0;
                  if (getTmeResponse.hasOwnProperty("PriceList")) {
                    // eslint-disable-next-line
                    getTmeResponse.PriceList.map((i) => {
                      if (i.Amount > newTQFirst) {
                        if (itmeFirst === 0) {
                          priceFirst = i.PriceValue;
                          subTotalPriceFirst = i.PriceValue * newTQFirst;
                        }
                        itmeFirst = +1;
                        // eslint-disable-next-line
                        return;
                      } else {
                        priceFirst = i.PriceValue;
                        subTotalPriceFirst = i.PriceValue * newTQFirst;
                        itmeFirst = +1;
                      }
                    });
                  }
                  let itmeSecond = 0;
                  if (getTmeResponse.hasOwnProperty("PriceList")) {
                    // eslint-disable-next-line
                    getTmeResponse.PriceList.map((i) => {
                      if (i.Amount > newTQSecond) {
                        if (itmeSecond === 0) {
                          priceSecond = i.PriceValue;
                          subTotalPriceSecond = i.PriceValue * newTQSecond;
                        }
                        itmeSecond = +1;
                        // eslint-disable-next-line
                        return;
                      } else {
                        priceSecond = i.PriceValue;
                        subTotalPriceSecond = i.PriceValue * newTQSecond;
                        itmeSecond = +1;
                      }
                    });
                  }
                  let itmeThird = 0;
                  if (getTmeResponse.hasOwnProperty("PriceList")) {
                    // eslint-disable-next-line
                    getTmeResponse.PriceList.map((i) => {
                      if (i.Amount > newTQThird) {
                        if (itmeThird === 0) {
                          priceThird = i.PriceValue;
                          subTotalPriceThird = i.PriceValue * newTQThird;
                        }
                        itmeThird = +1;
                        // eslint-disable-next-line
                        return;
                      } else {
                        priceThird = i.PriceValue;
                        subTotalPriceThird = i.PriceValue * newTQThird;
                        itmeThird = +1;
                      }
                    });
                  }
                  let fp = "";
                  let pine = "";
                  let Case = "";
                  if (getTmeResponse.hasOwnProperty("ParameterList")) {
                    // eslint-disable-next-line
                    getTmeResponse.ParameterList.map((paramList) => {
                      if (paramList.ParameterName === "Case - inch") {
                        fp = paramList.ParameterValue;
                        return null;
                      }
                    });
                    // eslint-disable-next-line
                    getTmeResponse.ParameterList.map((paramList) => {
                      if (paramList.ParameterName === "Case - mm") {
                        pine = paramList.ParameterValue;
                        return null;
                      }
                    });
                    // eslint-disable-next-line
                    getTmeResponse.ParameterList.map((paramList) => {
                      if (paramList.ParameterName === "Case") {
                        Case = paramList.ParameterValue;
                        return null;
                      }
                    });
                  }

                  footPrint =
                    pine === ""
                      ? Case + " " + fp
                      : Case + " " + fp + " (" + pine + " metric)";
                  gqData[items.partName].push({
                    counterId: (counterId = counterId + 1),
                    "Part Name": items.partName,
                    "Total Quantity 1": totalQtyFirst,
                    "Total Quantity 2": totalQtySecond,
                    "Total Quantity 3": totalQtyThird,
                    Footprint: footPrint,
                    manufacturerName: manufacturerName,
                    Supplier: "TME",
                    AvailabilityFirst: availabilityFirst,
                    AvailabilitySecond: availabilitySecond,
                    AvailabilityThird: availabilityThird,
                    "Unit Price 1": "€" + priceFirst,
                    "Unit Price 2": "€" + priceSecond,
                    "Unit Price 3": "€" + priceThird,
                    MOQ: moq,
                    "Subtotal Price 1":
                      "€" + Math.round(subTotalPriceFirst * 100) / 100,
                    "Subtotal Price 2":
                      "€" + Math.round(subTotalPriceSecond * 100) / 100,
                    "Subtotal Price 3":
                      "€" + Math.round(subTotalPriceThird * 100) / 100,
                  });
                }
              }
              if (
                !gqData[items.partName].some(
                  (el) =>
                    el["Part Name"] === items.partName &&
                    el["Supplier"] === "TME"
                )
              ) {
                gqData[items.partName].push({
                  counterId: (counterId = counterId + 1),
                  "Part Name": items.partName,
                  "Total Quantity 1": totalQtyFirst,
                  "Total Quantity 2": totalQtySecond,
                  "Total Quantity 3": totalQtyThird,
                  Footprint: "",
                  manufacturerName: "",
                  Supplier: "TME",
                  AvailabilityFirst: 0,
                  AvailabilitySecond: 0,
                  AvailabilityThird: 0,
                  "Unit Price 1": 0,
                  "Unit Price 2": 0,
                  "Unit Price 3": 0,
                  MOQ: 0,
                  "Subtotal Price 1": 0,
                  "Subtotal Price 2": 0,
                  "Subtotal Price 3": 0,
                });
              }
            }
            if (digikey) {
              let priceFirst = 0;
              let priceSecond = 0;
              let priceThird = 0;
              let footPrint = "";
              let manufacturerName = "";
              let availabilityFirst = "out of Stock";
              let availabilitySecond = "out of Stock";
              let availabilityThird = "out of Stock";
              let moq = 0;
              let subTotalPriceFirst = 0;
              let subTotalPriceSecond = 0;
              let subTotalPriceThird = 0;
              let getdigikeyResponse = await GenerateQuote.getDigikey(
                items.partName
              );
              if (getdigikeyResponse.success) {
                let dgData = getdigikeyResponse.data.ProductDetails;
                // eslint-disable-next-line
                dgData.map((item) => {
                  if (item.ManufacturerPartNumber === items.partName) {
                    if (item.hasOwnProperty("Supplier")) {
                      manufacturerName = item.Supplier;
                    }
                    if (totalQtyFirst < item.QuantityAvailable) {
                      availabilityFirst = item.QuantityAvailable;
                    }
                    if (totalQtyFirst < item.QuantityAvailable) {
                      availabilitySecond = item.QuantityAvailable;
                    }
                    if (totalQtyFirst < item.QuantityAvailable) {
                      availabilityThird = item.QuantityAvailable;
                    }
                    moq = item.MinimumOrderQuantity;
                    let newTQFirst = 0;
                    let newTQSecond = 0;
                    let newTQThird = 0;
                    if (moq > totalQtyFirst) {
                      newTQFirst = moq;
                    } else {
                      newTQFirst = totalQtyFirst;
                    }
                    if (moq > totalQtySecond) {
                      newTQSecond = moq;
                    } else {
                      newTQSecond = totalQtySecond;
                    }
                    if (moq > totalQtyThird) {
                      newTQThird = moq;
                    } else {
                      newTQThird = totalQtyThird;
                    }
                    if (item.hasOwnProperty("StandardPricing")) {
                      // eslint-disable-next-line
                      item.StandardPricing.map((i) => {
                        if (i.BreakQuantity > newTQFirst) {
                          // eslint-disable-next-line
                          return;
                        } else {
                          priceFirst = i.UnitPrice;
                          subTotalPriceFirst = i.UnitPrice * newTQFirst;
                        }
                      });
                      if (priceFirst === 0) {
                        priceFirst = item.UnitPrice;
                        subTotalPriceFirst = item.UnitPrice * newTQFirst;
                      }
                      // eslint-disable-next-line
                      item.StandardPricing.map((i) => {
                        if (i.BreakQuantity > newTQSecond) {
                          // eslint-disable-next-line
                          return;
                        } else {
                          priceSecond = i.UnitPrice;
                          subTotalPriceSecond = i.UnitPrice * newTQSecond;
                        }
                      });
                      if (priceSecond === 0) {
                        priceSecond = item.UnitPrice;
                        subTotalPriceSecond = item.UnitPrice * newTQSecond;
                      }
                      // eslint-disable-next-line
                      item.StandardPricing.map((i) => {
                        if (i.BreakQuantity > newTQThird) {
                          // eslint-disable-next-line
                          return;
                        } else {
                          priceThird = i.UnitPrice;
                          subTotalPriceThird = i.UnitPrice * newTQThird;
                        }
                      });
                      if (priceThird === 0) {
                        priceThird = item.UnitPrice;
                        subTotalPriceThird = item.UnitPrice * newTQThird;
                      }
                    }
                    if (item.hasOwnProperty("Parameters")) {
                      // eslint-disable-next-line
                      item.Parameters.map((params) => {
                        if (params.Parameter === "Package / Case") {
                          footPrint = params.Value;
                          return null;
                        }
                      });
                    }
                    gqData[items.partName].push({
                      counterId: (counterId = counterId + 1),
                      "Part Name": items.partName,
                      "Total Quantity 1": totalQtyFirst,
                      "Total Quantity 2": totalQtySecond,
                      "Total Quantity 3": totalQtyThird,
                      manufacturerName: manufacturerName,
                      Footprint: footPrint,
                      Supplier: "DigiKey",
                      AvailabilityFirst: availabilityFirst,
                      AvailabilitySecond: availabilitySecond,
                      AvailabilityThird: availabilityThird,
                      "Unit Price 1": "€" + priceFirst,
                      "Unit Price 2": "€" + priceSecond,
                      "Unit Price 3": "€" + priceThird,
                      MOQ: moq,
                      "Subtotal Price 1":
                        "€" + Math.round(subTotalPriceFirst * 100) / 100,
                      "Subtotal Price 2":
                        "€" + Math.round(subTotalPriceSecond * 100) / 100,
                      "Subtotal Price 3":
                        "€" + Math.round(subTotalPriceThird * 100) / 100,
                    });
                  }
                });
              }
              if (
                !gqData[items.partName].some(
                  (el) =>
                    el["Part Name"] === items.partName &&
                    el["Supplier"] === "DigiKey"
                )
              ) {
                gqData[items.partName].push({
                  counterId: (counterId = counterId + 1),
                  "Part Name": items.partName,
                  "Total Quantity 1": totalQtyFirst,
                  "Total Quantity 2": totalQtySecond,
                  "Total Quantity 3": totalQtyThird,
                  Footprint: "",
                  manufacturerName: manufacturerName,
                  Supplier: "DigiKey",
                  AvailabilityFirst: 0,
                  AvailabilitySecond: 0,
                  AvailabilityThird: 0,
                  "Unit Price 1": 0,
                  "Unit Price 2": 0,
                  "Unit Price 3": 0,
                  MOQ: 0,
                  "Subtotal Price 1": 0,
                  "Subtotal Price 2": 0,
                  "Subtotal Price 3": 0,
                });
              }
            }
            return gqData;
          },
          {
            concurrency: 1,
          }
        )
        .then((response) => {
          // eslint-disable-next-line
          response.map((newitem) => {
            let splowestFirst = 0;
            let splowestSecond = 0;
            let splowestThird = 0;
            let sptmpFirst, sptmpSecond, sptmpThird;
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(newitem)) {
              let arrowlowestFirst = 0;
              let arrowlowestSecond = 0;
              let arrowlowestThird = 0;
              let arrowtmpFirst, arrowtmpSecond, arrowtmpThird;
              let mouserlowestFirst = 0;
              let mouserlowestSecond = 0;
              let mouserlowestThird = 0;
              let mousertmpFirst, mousertmpSecond, mousertmpThird;
              let tmelowestFirst = 0;
              let tmelowestSecond = 0;
              let tmelowestThird = 0;
              let tmetmpFirst, tmetmpSecond, tmetmpThird;
              let farnelllowestFirst = 0;
              let farnelllowestSecond = 0;
              let farnelllowestThird = 0;
              let farnelltmpFirst, farnelltmpSecond, farnelltmpThird;
              let dglowestFirst = 0;
              let dglowestSecond = 0;
              let dglowestThird = 0;
              let dgtmpFirst, dgtmpSecond, dgtmpThird;
              // eslint-disable-next-line
              value.map((newitems) => {
                sptmpFirst =
                  newitems["Subtotal Price 1"] !== 0
                    ? parseFloat(
                        newitems["Subtotal Price 1"].replace(/[₹,€]/g, "")
                      )
                    : 0;
                sptmpSecond =
                  newitems["Subtotal Price 2"] !== 0
                    ? parseFloat(
                        newitems["Subtotal Price 2"].replace(/[₹,€]/g, "")
                      )
                    : 0;
                sptmpThird =
                  newitems["Subtotal Price 3"] !== 0
                    ? parseFloat(
                        newitems["Subtotal Price 3"].replace(/[₹,€]/g, "")
                      )
                    : 0;
                if (
                  newitems.AvailabilityFirst !== 0 &&
                  newitems.AvailabilityFirst !== "out of Stock"
                ) {
                  if (
                    (sptmpFirst > 0 && sptmpFirst < splowestFirst) ||
                    splowestFirst === 0
                  ) {
                    splowestFirst = sptmpFirst;
                  }
                }
                if (
                  newitems.AvailabilitySecond !== 0 &&
                  newitems.AvailabilitySecond !== "out of Stock"
                ) {
                  if (
                    (sptmpSecond > 0 && sptmpSecond < splowestSecond) ||
                    splowestSecond === 0
                  ) {
                    splowestSecond = sptmpSecond;
                  }
                }
                if (
                  newitems.AvailabilityThird !== 0 &&
                  newitems.AvailabilityThird !== "out of Stock"
                ) {
                  if (
                    (sptmpThird > 0 && sptmpThird < splowestThird) ||
                    splowestThird === 0
                  ) {
                    splowestThird = sptmpThird;
                  }
                }

                if (newitems["Supplier"] === "Arrow") {
                  setArrowData((arrowData) => [...arrowData, newitems]);

                  arrowtmpFirst =
                    newitems["Subtotal Price 1"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 1"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  arrowtmpSecond =
                    newitems["Subtotal Price 2"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 2"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  arrowtmpThird =
                    newitems["Subtotal Price 3"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 3"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  if (
                    newitems.AvailabilityFirst !== 0 &&
                    newitems.AvailabilityFirst !== "out of Stock"
                  ) {
                    if (
                      (arrowtmpFirst > 0 && arrowtmpFirst < arrowlowestFirst) ||
                      arrowlowestFirst === 0
                    ) {
                      arrowlowestFirst = arrowtmpFirst;
                    }
                  }
                  if (
                    newitems.AvailabilitySecond !== 0 &&
                    newitems.AvailabilitySecond !== "out of Stock"
                  ) {
                    if (
                      (arrowtmpSecond > 0 &&
                        arrowtmpSecond < arrowlowestSecond) ||
                      arrowlowestSecond === 0
                    ) {
                      arrowlowestSecond = arrowtmpSecond;
                    }
                  }
                  if (
                    newitems.AvailabilityThird !== 0 &&
                    newitems.AvailabilityThird !== "out of Stock"
                  ) {
                    if (
                      (arrowtmpThird > 0 && arrowtmpThird < arrowlowestThird) ||
                      arrowlowestThird === 0
                    ) {
                      arrowlowestThird = arrowtmpThird;
                    }
                  }
                }
                if (newitems["Supplier"] === "Farnell") {
                  setFarnellData((farnellData) => [...farnellData, newitems]);
                  farnelltmpFirst =
                    newitems["Subtotal Price 1"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 1"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  farnelltmpSecond =
                    newitems["Subtotal Price 2"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 2"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  farnelltmpThird =
                    newitems["Subtotal Price 3"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 3"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  if (
                    newitems.AvailabilityFirst !== 0 &&
                    newitems.AvailabilityFirst !== "out of Stock"
                  ) {
                    if (
                      (farnelltmpFirst > 0 &&
                        farnelltmpFirst < farnelllowestFirst) ||
                      farnelllowestFirst === 0
                    ) {
                      farnelllowestFirst = farnelltmpFirst;
                    }
                  }
                  if (
                    newitems.AvailabilitySecond !== 0 &&
                    newitems.AvailabilitySecond !== "out of Stock"
                  ) {
                    if (
                      (farnelltmpSecond > 0 &&
                        farnelltmpSecond < farnelllowestSecond) ||
                      farnelllowestSecond === 0
                    ) {
                      farnelllowestSecond = farnelltmpSecond;
                    }
                  }
                  if (
                    newitems.AvailabilityThird !== 0 &&
                    newitems.AvailabilityThird !== "out of Stock"
                  ) {
                    if (
                      (farnelltmpThird > 0 &&
                        farnelltmpThird < farnelllowestThird) ||
                      farnelllowestThird === 0
                    ) {
                      farnelllowestThird = farnelltmpThird;
                    }
                  }
                }
                if (newitems["Supplier"] === "TME") {
                  setTmeData((tmeData) => [...tmeData, newitems]);
                  tmetmpFirst =
                    newitems["Subtotal Price 1"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 1"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  tmetmpSecond =
                    newitems["Subtotal Price 2"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 2"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  tmetmpThird =
                    newitems["Subtotal Price 3"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 3"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  if (
                    newitems.AvailabilityFirst !== 0 &&
                    newitems.AvailabilityFirst !== "out of Stock"
                  ) {
                    if (
                      (tmetmpFirst > 0 && tmetmpFirst < tmelowestFirst) ||
                      tmelowestFirst === 0
                    ) {
                      tmelowestFirst = tmetmpFirst;
                    }
                  }
                  if (
                    newitems.AvailabilitySecond !== 0 &&
                    newitems.AvailabilitySecond !== "out of Stock"
                  ) {
                    if (
                      (tmetmpSecond > 0 && tmetmpSecond < tmelowestSecond) ||
                      tmelowestSecond === 0
                    ) {
                      tmelowestSecond = tmetmpSecond;
                    }
                  }
                  if (
                    newitems.AvailabilityThird !== 0 &&
                    newitems.AvailabilityThird !== "out of Stock"
                  ) {
                    if (
                      (tmetmpThird > 0 && tmetmpThird < tmelowestThird) ||
                      tmelowestThird === 0
                    ) {
                      tmelowestThird = tmetmpThird;
                    }
                  }
                }
                if (newitems["Supplier"] === "Mouser") {
                  setMouserData((mouserData) => [...mouserData, newitems]);
                  mousertmpFirst =
                    newitems["Subtotal Price 1"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 1"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  mousertmpSecond =
                    newitems["Subtotal Price 2"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 2"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  mousertmpThird =
                    newitems["Subtotal Price 3"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 3"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  if (
                    newitems.AvailabilityFirst !== 0 &&
                    newitems.AvailabilityFirst !== "out of Stock"
                  ) {
                    if (
                      (mousertmpFirst > 0 &&
                        mousertmpFirst < mouserlowestFirst) ||
                      mouserlowestFirst === 0
                    ) {
                      mouserlowestFirst = mousertmpFirst;
                    }
                  }
                  if (
                    newitems.AvailabilitySecond !== 0 &&
                    newitems.AvailabilitySecond !== "out of Stock"
                  ) {
                    if (
                      (mousertmpSecond > 0 &&
                        mousertmpSecond < mouserlowestSecond) ||
                      mouserlowestSecond === 0
                    ) {
                      mouserlowestSecond = mousertmpSecond;
                    }
                  }
                  if (
                    newitems.AvailabilityThird !== 0 &&
                    newitems.AvailabilityThird !== "out of Stock"
                  ) {
                    if (
                      (mousertmpThird > 0 &&
                        mousertmpThird < mouserlowestThird) ||
                      mouserlowestThird === 0
                    ) {
                      mouserlowestThird = mousertmpThird;
                    }
                  }
                }

                if (newitems["Supplier"] === "DigiKey") {
                  setDigikeyData((digikeyData) => [...digikeyData, newitems]);
                  dgtmpFirst =
                    newitems["Subtotal Price 1"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 1"].replace(/[₹,€]/g, "")
                        )
                      : 0;

                  dgtmpSecond =
                    newitems["Subtotal Price 2"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 2"].replace(/[₹,€]/g, "")
                        )
                      : 0;
                  dgtmpThird =
                    newitems["Subtotal Price 3"] !== 0
                      ? parseFloat(
                          newitems["Subtotal Price 3"].replace(/[₹,€]/g, "")
                        )
                      : 0;

                  if (
                    newitems.AvailabilityFirst !== 0 &&
                    newitems.AvailabilityFirst !== "out of Stock"
                  ) {
                    if (
                      (dgtmpFirst > 0 && dgtmpFirst < dglowestFirst) ||
                      dglowestFirst === 0
                    ) {
                      dglowestFirst = dgtmpFirst;
                    }
                  }
                  if (
                    newitems.AvailabilitySecond !== 0 &&
                    newitems.AvailabilitySecond !== "out of Stock"
                  ) {
                    if (
                      (dgtmpSecond > 0 && dgtmpSecond < dglowestSecond) ||
                      dglowestSecond === 0
                    ) {
                      dglowestSecond = dgtmpSecond;
                    }
                  }
                  if (
                    newitems.AvailabilityThird !== 0 &&
                    newitems.AvailabilityThird !== "out of Stock"
                  ) {
                    if (
                      (dgtmpThird > 0 && dgtmpThird < dglowestThird) ||
                      dglowestThird === 0
                    ) {
                      dglowestThird = dgtmpThird;
                    }
                  }
                }
              });
              setArrowTotalFirst(
                (arrowTotalFirst) => arrowTotalFirst + arrowlowestFirst
              );
              setArrowTotalSecond(
                (arrowTotalSecond) => arrowTotalSecond + arrowlowestSecond
              );
              setArrowTotalThird(
                (arrowTotalThird) => arrowTotalThird + arrowlowestThird
              );
              setTmeTotalFirst(
                (tmeTotalFirst) => tmeTotalFirst + tmelowestFirst
              );
              setTmeTotalSecond(
                (tmeTotalSecond) => tmeTotalSecond + tmelowestSecond
              );
              setTmeTotalThird(
                (tmeTotalThird) => tmeTotalThird + tmelowestThird
              );
              setFarnellTotalFirst(
                (farnellTotalFirst) => farnellTotalFirst + farnelllowestFirst
              );
              setFarnellTotalSecond(
                (farnellTotalSecond) => farnellTotalSecond + farnelllowestSecond
              );
              setFarnellTotalThird(
                (farnellTotalThird) => farnellTotalThird + farnelllowestThird
              );
              setMouserTotalFirst(
                (mouserTotalFirst) => mouserTotalFirst + mouserlowestFirst
              );
              setMouserTotalSecond(
                (mouserTotalSecond) => mouserTotalSecond + mouserlowestSecond
              );
              setMouserTotalThird(
                (mouserTotalThird) => mouserTotalThird + mouserlowestThird
              );
              setDigikeyTotalFirst(
                (digikeyTotalFirst) => digikeyTotalFirst + dglowestFirst
              );
              setDigikeyTotalSecond(
                (digikeyTotalSecond) => digikeyTotalSecond + dglowestSecond
              );
              setDigikeyTotalThird(
                (digikeyTotalThird) => digikeyTotalThird + dglowestThird
              );
            }
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(newitem)) {
              // eslint-disable-next-line
              value.map((newitems) => {
                newitems.firstValid = false;
                newitems.secondValid = false;
                newitems.thirdValid = false;
                sptmpFirst =
                  newitems["Subtotal Price 1"] !== 0
                    ? parseFloat(
                        newitems["Subtotal Price 1"].replace(/[₹,€]/g, "")
                      )
                    : 0;
                sptmpSecond =
                  newitems["Subtotal Price 2"] !== 0
                    ? parseFloat(
                        newitems["Subtotal Price 2"].replace(/[₹,€]/g, "")
                      )
                    : 0;
                sptmpThird =
                  newitems["Subtotal Price 3"] !== 0
                    ? parseFloat(
                        newitems["Subtotal Price 3"].replace(/[₹,€]/g, "")
                      )
                    : 0;
                if (
                  sptmpFirst === splowestFirst &&
                  sptmpFirst !== 0 &&
                  newitems.AvailabilityFirst !== 0 &&
                  newitems.AvailabilityFirst !== "out of Stock"
                ) {
                  newitems.firstValid = true;
                }
                if (
                  sptmpSecond === splowestSecond &&
                  sptmpSecond !== 0 &&
                  newitems.AvailabilitySecond !== 0 &&
                  newitems.AvailabilitySecond !== "out of Stock"
                ) {
                  newitems.secondValid = true;
                }
                if (
                  sptmpThird === splowestThird &&
                  sptmpThird !== 0 &&
                  newitems.AvailabilityThird !== 0 &&
                  newitems.AvailabilityThird !== "out of Stock"
                ) {
                  newitems.thirdValid = true;
                }
                setGenerateExcelData((generateExcelData) => [
                  ...generateExcelData,
                  newitems,
                ]);
                setGenerateQuotoData((generateQuotoData) => [
                  ...generateQuotoData,
                  newitems,
                ]);
              });
            }
          });

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: err.message,
            icon: "error",
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
      });
    }
  };
  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
          <h3 className="spinnerMessage">{loadingMessage}</h3>
        </div>
      ) : (
        ""
      )}
      <div className="container">
        <div className="main-content">
          <header className="jumbotron">
            <h3 className="row justify-content-md-center">
              Electronic Component Quoter
            </h3>
          </header>

          <div style={{ maxHeight: "250px", overflow: "auto" }}>
            <table className="table">
              <thead>
                <tr key="pastData">
                  <th key="partName">partName</th>
                  <th key="unitsPerPCB">unitsPerPCB</th>
                </tr>
              </thead>
              <tbody>
                {pastedData.length > 0 &&
                  pastedData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td key={rowIndex + row.partName}>{row.partName}</td>
                      <td
                        key={rowIndex + row.unitsPerPCB}
                        className={row.color}
                      >
                        {row.unitsPerPCB}
                      </td>
                    </tr>
                  ))}
                {pastedData.length === 0 && (
                  <tr>
                    <td className={style} onClick={changeStyle}>
                      {pastedMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <hr />
          <div className="form-outline">
            <label className="form-label" htmlFor="pcbquantityfirst">
              PCBs Quantity 1
            </label>
            <input
              onChange={(e) =>
                setPcbquantityFirst((v) =>
                  e.target.validity.valid
                    ? e.target.value.replace(/\D/g, "")
                    : v
                )
              }
              type="text"
              id="pcbquantityfirst"
              name="pcbquantityfirst"
              className="form-control"
              min="1"
              value={pcbquantityFirst}
            />
            <label className="form-label" htmlFor="pcbquantitysecond">
              PCBs Quantity 2
            </label>
            <input
              onChange={(e) =>
                setPcbquantitySecond((v) =>
                  e.target.validity.valid
                    ? e.target.value.replace(/\D/g, "")
                    : v
                )
              }
              type="text"
              id="pcbquantitysecond"
              name="pcbquantitysecond"
              className="form-control"
              min="1"
              value={pcbquantitySecond}
            />
            <label className="form-label" htmlFor="pcbquantitythird">
              PCBs Quantity 3
            </label>
            <input
              onChange={(e) =>
                setPcbquantityThird((v) =>
                  e.target.validity.valid
                    ? e.target.value.replace(/\D/g, "")
                    : v
                )
              }
              type="text"
              id="pcbquantitythird"
              name="pcbquantitythird"
              className="form-control"
              min="1"
              value={pcbquantityThird}
            />
            <hr />
            <span className="form-label">Supplier</span>
            <div className="form-check">
              <input
                onChange={(e) => setArrow(!arrow)}
                className="form-check-input"
                type="checkbox"
                checked={arrow}
                id="arrow"
                name="arrow"
              />
              <label className="form-check-label" htmlFor="arrow">
                Arrow
              </label>
            </div>
            <div className="form-check">
              <input
                onChange={(e) => setTme(!tme)}
                className="form-check-input"
                type="checkbox"
                checked={tme}
                id="tme"
                name="tme"
              />
              <label className="form-check-label" htmlFor="tme">
                TME
              </label>
            </div>
            <div className="form-check">
              <input
                onChange={(e) => setFarnell(!farnell)}
                className="form-check-input"
                type="checkbox"
                checked={farnell}
                id="farnell"
                name="farnell"
              />
              <label className="form-check-label" htmlFor="farnell">
                Farnell
              </label>
            </div>
            <div className="form-check">
              <input
                onChange={(e) => setDigikey(!digikey)}
                className="form-check-input"
                type="checkbox"
                checked={digikey}
                id="digikey"
                name="digikey"
              />
              <label className="form-check-label" htmlFor="digikey">
                DigiKey
              </label>
            </div>
            <div className="form-check">
              <input
                onChange={(e) => setMouser(!mouser)}
                className="form-check-input"
                type="checkbox"
                checked={mouser}
                id="mouser"
                name="mouser"
              />
              <label className="form-check-label" htmlFor="mouser">
                Mouser
              </label>
            </div>
          </div>
          <div className="form-group mt-4">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm me-1"
              onClick={handleGenerateQuote}
            >
              Generate Quotation
            </button>

            <button
              type="button"
              className="btn btn-outline-warning btn-sm"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
          <hr />
          {arrowData.length > 0 ||
          tmeData.length > 0 ||
          mouserData.length > 0 ||
          digikeyData.length > 0 ||
          farnellData.length > 0 ? (
            <div>
              <ExportToExcel
                excelData={generateExcelData}
                excelTotalData={generateExcelTotalData}
                fileName={fileName}
              />{" "}
              <hr />
              <div style={{ margin: "12px" }}>
                <div className="row border">
                  <div className="col border"></div>
                  <div className="col border">PCBs Quantity 1</div>
                  <div className="col border">PCBs Quantity 2</div>
                  <div className="col border">PCBs Quantity 3</div>
                </div>
                {tmeData.length > 0 && (
                  <div className="row border">
                    <div className="col border">TME Total</div>
                    <div className="col border">€{tmeTotalFirst}</div>
                    <div className="col border">€{tmeTotalSecond}</div>
                    <div className="col border">€{tmeTotalThird}</div>
                  </div>
                )}
                {arrowData.length > 0 && (
                  <div className="row border">
                    <div className="col border">Arrow Total</div>
                    <div className="col border">€{arrowTotalFirst}</div>
                    <div className="col border">€{arrowTotalSecond}</div>
                    <div className="col border">€{arrowTotalThird}</div>
                  </div>
                )}
                {mouserData.length > 0 && (
                  <div className="row border">
                    <div className="col border">Mouser Total</div>
                    <div className="col border">€{mouserTotalFirst}</div>
                    <div className="col border">€{mouserTotalSecond}</div>
                    <div className="col border">€{mouserTotalThird}</div>
                  </div>
                )}
                {farnellData.length > 0 && (
                  <div className="row border">
                    <div className="col border">Farnell Total</div>
                    <div className="col border">€{farnellTotalFirst}</div>
                    <div className="col border">€{farnellTotalSecond}</div>
                    <div className="col border">€{farnellTotalThird}</div>
                  </div>
                )}
                {digikeyData.length > 0 && (
                  <div className="row border">
                    <div className="col border">DigiKey Total</div>
                    <div className="col border">€{digikeyTotalFirst}</div>
                    <div className="col border">€{digikeyTotalSecond}</div>
                    <div className="col border">€{digikeyTotalThird}</div>
                  </div>
                )}
              </div>
              <div style={{ maxHeight: "300px", overflow: "auto" }}>
                <table className="table bordered">
                  <thead>
                    <tr key="generate Quote Data">
                      <th key="Part Name">Part Name</th>
                      <th key="Footprint">Footprint</th>
                      <th key="Supplier">Supplier</th>
                      <th key="Manufacturer Name">Manufacturer Name</th>
                      <th key="MOQ">MOQ</th>
                      <th key="Availability 1">Availability 1</th>
                      <th key="Total Quantity 1">Total Quantity 1</th>
                      <th key="Unit Price 1">Unit Price 1</th>
                      <th key="Subtotal Price 1">Subtotal Price 1</th>
                      <th key="Availability 2">Availability 2</th>
                      <th key="Total Quantity 2">Total Quantity 2</th>
                      <th key="Unit Price 2">Unit Price 2</th>
                      <th key="Subtotal Price 2">Subtotal Price 2</th>
                      <th key="Availability 3">Availability 3</th>
                      <th key="Total Quantity 3">Total Quantity 3</th>
                      <th key="Unit Price 3">Unit Price 3</th>
                      <th key="Subtotal Price 3">Subtotal Price 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    {generateQuotoData.length > 0 &&
                      generateQuotoData.map((row, rowIndex) => (
                        <tr key={rowIndex + row + 1}>
                          <td key={row + row["Part Name"] + 2}>
                            {row["Part Name"]}
                          </td>
                          <td key={row + row["Footprint"] + 3}>
                            {row["Footprint"]}
                          </td>
                          <td key={row + row["Supplier"] + 4}>
                            {row["Supplier"]}
                          </td>
                          <td key={row + row["manufacturerName"] + 5}>
                            {row["manufacturerName"]}
                          </td>
                          <td key={row + row["MOQ"] + 6}>{row["MOQ"]}</td>
                          <td key={row + row["AvailabilityFirst"] + 7}>
                            {row["AvailabilityFirst"]}
                          </td>
                          <td key={row + row["Total Quantity 1"] + 8}>
                            {row["Total Quantity 1"]}
                          </td>
                          <td key={row + row["Unit Price 1"] + 9}>
                            {row["Unit Price 1"]}
                          </td>
                          <td
                            className={row.firstValid ? "valid" : ""}
                            key={row + row["Subtotal Price 1"] + 10}
                          >
                            {row["Subtotal Price 1"]}
                          </td>
                          <td key={row + row["AvailabilitySecond"] + 11}>
                            {row["AvailabilitySecond"]}
                          </td>
                          <td key={row + row["Total Quantity 2"] + 12}>
                            {row["Total Quantity 2"]}
                          </td>
                          <td key={row + row["Unit Price 2"] + 13}>
                            {row["Unit Price 2"]}
                          </td>
                          <td
                            className={row.secondValid ? "valid" : ""}
                            key={row + row["Subtotal Price 2"] + 14}
                          >
                            {row["Subtotal Price 2"]}
                          </td>
                          <td key={row + row["AvailabilityThird"] + 15}>
                            {row["AvailabilityThird"]}
                          </td>
                          <td key={row + row["Total Quantity 3"] + 16}>
                            {row["Total Quantity 3"]}
                          </td>
                          <td key={row + row["Unit Price 3"] + 17}>
                            {row["Unit Price 3"]}
                          </td>
                          <td
                            className={row.thirdValid ? "valid" : ""}
                            key={row + row["Subtotal Price 3"] + 18}
                          >
                            {row["Subtotal Price 3"]}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <hr />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
export default UserDashboard;
