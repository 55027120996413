import axios from "axios";
import authHeader from "./auth-header";
const apiUrl = `https://quoter.zbeacon.info/api/`;
// const apiUrl = `http://localhost:8888/api/`;
class GenerateQuote {
  login(data) {
    return axios({
      method: "post",
      url: apiUrl + `login`,
      headers: authHeader(),
      data: {
        password: data,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return { Error: err.message };
      });
  }
  getArrow(partName) {
    return axios({
      method: "post",
      url: apiUrl + `arrowData`,
      headers: authHeader(),
      data: {
        partName: partName,
      },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return { Error: err.message };
      });
  }
  getMouser(partName) {
    return axios({
      method: "post",
      url: apiUrl + `mouserData`,
      headers: authHeader(),
      data: {
        partName: partName,
      },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return { Error: err.message };
      });
  }
  getFarnell(partName) {
    return axios({
      method: "post",
      url: apiUrl + `farnellData`,
      headers: authHeader(),
      data: {
        partName: partName,
      },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return { Error: err.message };
      });
  }
  getTme(partName) {
    return axios({
      method: "post",
      url: apiUrl + `tmeData`,
      headers: authHeader(),
      data: {
        partName: partName,
      },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return { Error: err.message };
      });
  }
  getDigikey(partName) {
    return axios({
      method: "post",
      url: apiUrl + `digikeyData`,
      headers: authHeader(),
      data: {
        partName: partName,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return { Error: err.message };
      });
  }
}
// eslint-disable-next-line
export default new GenerateQuote();
